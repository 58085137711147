import { Box, Typography } from "@mui/material";
import CreateEditUsername from "../../../components/CreateEditUsername";
import CreateEditCountry from "../../../components/CreateEditCountry";
import CreateEditStatus from "../../../components/CreateEditStatus";
import CreateEditBirthdate from "../../../components/CreateEditBirthdate";
import CreateEditGender from "../../../components/CreateEditGenre";
import CreateEditHeight from "../../../components/CreateEditHeight";
import CreateEditActivityLevel from "../../../components/CreateEditActivityLevel";
import CreateEditWeight from "../../../components/CreateEditWeight";
import { useUserSession } from "../../../context/SessionProvider";
import { useTranslation } from "react-i18next";
import ChooseLanguage from "../../../components/ChooseLanguage";

const Personal = () => {
  const { t } = useTranslation(["creatorSettings"]);
  const session = useUserSession();

  return (
    <>
      <Typography sx={{ color: "black", mt: 3, mb: 1 }} variant="h4">
        {t("perInfo")}
      </Typography>
      <ChooseLanguage />
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, pb: 3 }}>
        <CreateEditUsername username={session.customer.username} />
        <CreateEditCountry country_id={session.customer.country_id} />
        <CreateEditStatus status={session.customer.status} />
        <CreateEditBirthdate birthdate={session.customer.birthdate} />
        <CreateEditGender gender={session.customer.gender} />
        <CreateEditHeight height={session.customer.height} />
        <CreateEditActivityLevel status={session.customer.activity_level} />
        <CreateEditWeight weight={session.customer.weight} />
      </Box>
    </>
  );
};

export default Personal;
