import useSWR from "swr";
import { API_BASE_URL } from "../utils";

const top50mostExecutedWorkouts = async function () {
  try {
    const response = await fetch(`${API_BASE_URL}/top50mostExecutedWorkouts`);
    const responseJson = await response.json();
    console.log("responseJson: top50mostExecutedWorkouts", responseJson);
    if (responseJson.status === "ok") {
      return responseJson.data;
    } else {
      throw new Error("The fetch was NOT succesfull");
    }
  } catch (error) {
    console.error("top50mostExecutedWorkouts error", error);
    throw new Error("The fetch was NOT succesfull");
  }
};

const useTop50WorldWorkoutsToday = function () {
  const { data } = useSWR("top50today", top50mostExecutedWorkouts);
  console.log("top 50: ", data);
  return {
    workouts: data,
  };
};

export default useTop50WorldWorkoutsToday;
