export const businessLogic = {
  createLimit: {
    userPremium: {
      exercisesLimit: 200,
      workoutsLimit: 75,
      routinesLimit: 15,
    },
    userNoPremium: {
      exercisesLimit: 15,
      workoutsLimit: 3,
      routinesLimit: 1,
    },
  },
  likeLimit: {
    userPremium: {
      // This limit is to avoid hackers with infinite content.
      exercisesLimit: 4000,
      workoutsLimit: 4000,
      routinesLimit: 4000,
    },
    userNoPremium: {
      exercisesLimit: 300,
      workoutsLimit: 200,
      routinesLimit: 100,
    },
  },
  defaultRoutines: ["id1pushpull", "id2ronny"],
  memoryCreateLimits: {
    photo: {
      premiumLimitPh: 10 * 1024 * 1024,
      nonPremiumLimitPh: 2 * 1024 * 1024,
    },
    video: {
      premiumLimitV: 200 * 1024 * 1024,
      nonPremiumLimitV: 50 * 1024 * 1024,
    },
  },
  contentCreator: {
    minFollowers: 100,
    premium: true,
  },

  withdraw: {
    minBalance: 100,
    minDaysInterval: 7,
  },
};
