import { useEffect } from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { useUserSession } from "../../context/SessionProvider";
import useSubscriptions from "../../hooks/useSubscriptions";
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { businessLogic } from "../../utils/businessLogic";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "sonner";
import useUser from "../../hooks/useUser";
import ItemCard from "../../components/ItemCard";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const CLIENT_ID =
  "AaJs1jhr0FXnwj5OdXxkGS0YmSaJ-Bw8WxZ45opELLzjqJ2UGyExBfflSAEHhHnsIHF-TA1Jqs0x_JJ5";

const SUBSCRIPTION_PLAN_NOTRIAL_ID = "P-44578063H67056110M4N56IA";
const SUBSCRIPTION_PLAN_TRIAL_ID = "P-018722378H3454524M4GBHAA";

const ButtonWrapper = ({ type, is_first_time }) => {
  const [{ options }, dispatch] = usePayPalScriptReducer();
  const session = useUserSession();

  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        intent: "subscription",
      },
    });
  }, [type]);

  return options.isPending ? (
    <CircularProgress />
  ) : (
    <PayPalButtons
      createSubscription={(data, actions) => {
        return actions.subscription
          .create({
            plan_id: is_first_time
              ? SUBSCRIPTION_PLAN_TRIAL_ID
              : SUBSCRIPTION_PLAN_NOTRIAL_ID,
            custom_id: session?.customer?.customer_id,
          })
          .then((orderId) => {
            return orderId;
          })
          .catch((err) => {
            toast.error("There was an error with the purchase.");
            console.error("Error buying is: ", err);
          });
      }}
      style={{
        label: "subscribe",
      }}
    />
  );
};

export default function App() {
  const { t } = useTranslation(["premium"]);
  const session = useUserSession();
  const isPremium = session?.customer?.premium;

  console.log("is premium ", isPremium);

  const currentCreatorId = session?.customer?.creator_id;

  const { user } = useUser({ user_id: currentCreatorId });

  const { mySubscriptions, isLoading } = useSubscriptions();
  const is_first_time =
    Array.isArray(mySubscriptions) && mySubscriptions.length == 0;
  console.log("mySubscriptions: ", mySubscriptions, is_first_time);

  const { createLimit, likeLimit } = businessLogic;
  const { userPremium, userNoPremium } = createLimit;

  const {
    userPremium: userPremiumLikeLimit,
    userNoPremium: userNoPremiumLikeLimit,
  } = likeLimit;

  if (!mySubscriptions) {
    return <CircularProgress size={40} />;
  }

  return (
    <PayPalScriptProvider
      options={{
        clientId: CLIENT_ID,
        components: "buttons",
        intent: "subscription",
        vault: true,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          gap: 2,
          p: 4,
          overflowY: "auto",
        }}
      >
        <Typography variant="h3" fontWeight={"bold"}>
          {is_first_time ? t("getFree") : "Premium"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            gap: { xs: 3, md: 10 },
            flexDirection: {
              xs: "column-reverse",
              md: "row",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "350px",
              height: "600px",
              borderRadius: "0.30rem",
              boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
              px: 4,
              py: 2,
              background:
                "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(184,180,180,1) 100%)",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography
                variant="h4"
                fontWeight={"bold"}
                sx={{
                  width: "100%",
                  borderBottom: "1px solid rgba(0,0,0,0.1)",
                  py: 2,
                  display: "flex",
                }}
              >
                $0.00
              </Typography>
              <Typography variant="h5">{t("default")}</Typography>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    alignItems: "center",
                  }}
                >
                  <CloseIcon sx={{ fontSize: 30 }} />
                  <Typography fontWeight={"bold"} variant="p">
                    {t("noPublicity")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    alignItems: "center",
                  }}
                >
                  <CloseIcon sx={{ fontSize: 30 }} />
                  <Typography fontWeight={"bold"} variant="p">
                    60 {t("ofEnergy")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    alignItems: "center",
                  }}
                >
                  <CloseIcon sx={{ fontSize: 30 }} />
                  <Typography fontWeight={"bold"} variant="p">
                    {t("creationLimit")}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    alignItems: "center",
                  }}
                >
                  <CloseIcon sx={{ fontSize: 30 }} />
                  <Typography fontWeight={"bold"} variant="p">
                    {t("likeLimit")}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    alignItems: "center",
                  }}
                >
                  <CloseIcon sx={{ fontSize: 30 }} />
                  <Typography fontWeight={"bold"} variant="p">
                    {t("premiumBadge")}
                  </Typography>
                </Box>
              </Box>
              {!isPremium && (
                <Box
                  sx={{
                    width: "100%",
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "white",
                    borderRadius: "0.30rem",
                    boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                    background:
                      "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(184,180,180,1) 100%)",
                  }}
                >
                  {t("activePlan")}
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "350px",
              height: "600px",
              borderRadius: "0.30rem",
              boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
              px: 4,
              py: 2,
              background:
                "radial-gradient(circle, rgba(0,0,0,0.8435749299719888) 0%, rgba(242,211,0,1) 100%)",
              animation: "breathing 4s ease-in-out infinite", // Adding the breathing animation
              "@keyframes breathing": {
                "0%": {
                  transform: "scale(1)",
                },
                "50%": {
                  transform: "scale(1.03)", // Scale up slightly
                },
                "100%": {
                  transform: "scale(1)", // Back to original size
                },
              },
              ":hover": {
                animation: "none", // Disable animation on hover
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography
                variant="h4"
                fontWeight={"bold"}
                sx={{
                  width: "100%",
                  borderBottom: "1px solid rgba(255,255,255,0.3)",
                  py: 2,
                  display: "flex",
                  color: "white",
                }}
              >
                {is_first_time ? "$0.00" : "$2.99"}
              </Typography>
              <Typography variant="h5" color={"white"}>
                {is_first_time ? t("freeTrialGet") : "Premium"}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {is_first_time && (
                  <Box
                    sx={{
                      display: "flex",
                      gap: 0.5,
                      alignItems: "center",
                    }}
                  >
                    <CheckIcon sx={{ fontSize: 30, color: "white" }} />
                    <Typography fontWeight={"bold"} variant="p" color={"white"}>
                      {t("noCommit")}
                    </Typography>
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    alignItems: "center",
                  }}
                >
                  <CheckIcon sx={{ fontSize: 30, color: "white" }} />
                  <Typography fontWeight={"bold"} variant="p" color={"white"}>
                    {t("cancelAny")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    alignItems: "center",
                  }}
                >
                  <CheckIcon sx={{ fontSize: 30, color: "white" }} />
                  <Typography fontWeight={"bold"} variant="p" color={"white"}>
                    {t("noPub")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    alignItems: "center",
                  }}
                >
                  <CheckIcon sx={{ fontSize: 30, color: "white" }} />
                  <Typography fontWeight={"bold"} variant="p" color={"white"}>
                    1000 {t("ofEnergy")}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    alignItems: "center",
                  }}
                >
                  <CheckIcon sx={{ fontSize: 30, color: "white" }} />
                  <Typography fontWeight={"bold"} variant="p" color={"white"}>
                    {t("noLimitCre")}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    alignItems: "center",
                  }}
                >
                  <CheckIcon sx={{ fontSize: 30, color: "white" }} />
                  <Typography fontWeight={"bold"} variant="p" color={"white"}>
                    {t("noLimitLike")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    alignItems: "center",
                  }}
                >
                  <CheckIcon sx={{ fontSize: 30, color: "white" }} />
                  <Typography fontWeight={"bold"} variant="p" color={"white"}>
                    {t("premiumBadge")}
                  </Typography>
                </Box>
              </Box>

              {isPremium ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "white",
                    borderRadius: "0.30rem",
                    boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                    background:
                      "radial-gradient(circle, rgba(0,0,0,0.8435749299719888) 0%, rgba(242,211,0,1) 100%)",
                    color: "white",
                  }}
                >
                  {t("activePlan")}
                </Box>
              ) : (
                <ButtonWrapper
                  type={"subscriptions"}
                  is_first_time={is_first_time}
                />
              )}
            </Box>
          </Box>
        </Box>
        {currentCreatorId && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              borderRadius: "0.30rem",
              boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
              p: 1,
              my: 3,
              maxWidth: "300px",
              color: "black",
              textDecoration: "none",
            }}
            component={Link}
            to={"/app/settings/personal"}
          >
            <Typography sx={{ color: "black" }} variant="h4">
              {t("creator")}
            </Typography>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              {user && <ItemCard item={user} />}
            </Box>
          </Box>
        )}
        {mySubscriptions && mySubscriptions.length > 0 && (
          <Box sx={{ pb: 3 }}>
            <Typography variant="h4">{t("miSub")}</Typography>
            <Box sx={{ width: "100%" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="subscriptions table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("status")}</TableCell>
                      <TableCell>{t("trial")}</TableCell>
                      <TableCell>{t("payMet")}</TableCell>
                      <TableCell>{t("date")}</TableCell>
                      <TableCell>{t("lastPaid")}</TableCell>
                      <TableCell>{t("subId")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mySubscriptions.map((sub) => (
                      <TableRow key={sub.subscription_id}>
                        <TableCell>{sub.status}</TableCell>
                        <TableCell>{sub.is_trial ? "Yes" : "No"}</TableCell>
                        <TableCell>{sub.pay_method}</TableCell>
                        <TableCell>
                          {new Date(sub.created_at).toLocaleDateString()}
                        </TableCell>
                        <TableCell>
                          {sub.last_paid
                            ? new Date(sub.last_paid).toLocaleDateString()
                            : "-"}
                        </TableCell>
                        <TableCell>{sub.subscription_id}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        )}
      </Box>
    </PayPalScriptProvider>
  );
}
