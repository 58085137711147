import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ChooseLanguage = () => {
  const { t, i18n } = useTranslation(["compChooseLanguage"]);
  const [language, setLanguage] = useState(i18n.language); // Default to current language

  const handleChangeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage); // Switch language in i18next
  };

  return (
    <Box
      sx={{
        borderRadius: "0.30rem",
        boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
        p: 2,
        mb: 2,
      }}
    >
      <Typography variant="h5" fontWeight="bold" marginY={1}>
        {t("language")} {/* "Choose your language" */}
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
        <FormControl sx={{ width: "300px" }}>
          <InputLabel id="language-select-label">{t("language")}</InputLabel>
          <Select
            value={language}
            onChange={(e) => handleChangeLanguage(e.target.value)}
            labelId="language-select-label"
            label={t("language")}
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="es">Español</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default ChooseLanguage;
