import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { validUuid } from "../../utils";
import { toast } from "sonner";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import useCustomerExercisesExecution from "../../hooks/useCustomerExercisesExecution";
import Home from "../Home";
import useCustomerWeight from "../../hooks/useCustomerWeight";
import useCustomerWater from "../../hooks/useCustomerWater";

import useUser from "../../hooks/useUser";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useTranslation } from "react-i18next";

const User = () => {
  const { user_id } = useParams();
  const { t } = useTranslation(["user"]);
  const { customerWeightArr } = useCustomerWeight({ customer_id: user_id });
  const { customerWaterArr } = useCustomerWater({ customer_id: user_id });
  const { settingsCustomerExercisesArr } = useCustomerExercisesExecution({
    customer_id: user_id,
  });

  const {
    user,
    error,
    isFollowing,
    likeDisabled,
    handleFollowUser,
    handleUnfollowUser,
    isTheLoggedInUser,
    isLoading,
    readFollowResLoading,
    readFollowError,
  } = useUser({
    user_id,
  });

  useEffect(() => {
    if (!validUuid(user_id)) {
      toast.error(
        `The user id "${user_id}" is incorrect. The correct format is: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.`
      );
    }
  }, [user_id]);
  console.log("error || readFollowError_ ", error, readFollowError);
  if (error || readFollowError) {
    return (
      <Typography variant="h4" sx={{ fontWeight: "bold", mx: 2 }}>
        {t("userNotFound")}
      </Typography>
    );
  }
  console.log("user", user);
  if (
    !user ||
    !settingsCustomerExercisesArr ||
    !customerWeightArr ||
    !customerWaterArr ||
    isLoading ||
    readFollowResLoading
  ) {
    return <CircularProgress />;
  }
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        overflowY: "auto",
      }}
    >
      <Box p={2}>
        <Box sx={{ display: "flex", gap: 3 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h3" fontWeight={"bold"}>
              {user.username}
            </Typography>
            {user.verified && (
              <VerifiedIcon
                sx={{
                  ml: 2,
                  width: "40px",
                  height: "40px",
                }}
              />
            )}
          </Box>
          {isTheLoggedInUser !== true && (
            <>
              {isFollowing ? (
                <Button
                  disabled={likeDisabled}
                  sx={{
                    backgroundColor: "red",
                    color: "white",
                    fontWeight: "bold",
                    textTransform: "none", // Disable uppercase transformation
                    opacity: likeDisabled && "0.8",
                    ":hover": {
                      backgroundColor: "#cc0000", // Slightly darker red on hover
                    },
                  }}
                  onClick={function () {
                    handleUnfollowUser();
                  }}
                >
                  {t("unfollow")}
                </Button>
              ) : (
                <Button
                  disabled={likeDisabled}
                  sx={{
                    backgroundColor: "red",
                    color: "white",
                    fontWeight: "bold",
                    opacity: likeDisabled && "0.8",

                    textTransform: "none", // Disable uppercase transformation
                    ":hover": {
                      backgroundColor: "#cc0000", // Slightly darker red on hover
                    },
                  }}
                  onClick={function () {
                    handleFollowUser();
                  }}
                >
                  {t("follow")}
                </Button>
              )}
            </>
          )}
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography variant="p" sx={{ fontWeight: "bold" }}>
              {user.followers_count}
            </Typography>
            <Typography variant="p">{t("followers")}</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography variant="p" sx={{ fontWeight: "bold" }}>
              {user.following_count}
            </Typography>
            <Typography variant="p">{t("following")}</Typography>
          </Box>
        </Box>
        {user.description ? (
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            {user.description}
          </Typography>
        ) : (
          <Typography variant="subtitle1" sx={{ color: "rgba(0,0,0,0.5)" }}>
            {t("noDesc")}
          </Typography>
        )}
      </Box>

      <Home isForViewUser={true} viewUserId={user_id} user={user} />
    </Box>
  );
};

export default User;
