import React, { lazy, Suspense, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@aws-amplify/ui-react/styles.css";
import Search from "./routes/Search";
import Root from "./routes/Root";
import "./config/i18next";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import AuthStyle from "./AuthStyle";

import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from "react-router-dom";

import theme from "./theme";
import amplifyconfig from "./amplifyconfiguration.json";
import { Amplify } from "aws-amplify";
import { Authenticator, Flex, Heading, Image } from "@aws-amplify/ui-react";

import { CircularProgress, ThemeProvider } from "@mui/material";
import SessionProvider, { useUserSession } from "./context/SessionProvider";
import CssBaseline from "@mui/material/CssBaseline";
import { toast, Toaster } from "sonner";
import { SWRConfig } from "swr";

import Exercise from "./routes/Excersise";
import User from "./routes/User";
import Workout from "./routes/Workout";
import Home from "./routes/Home";
import Weight from "./routes/Weight";
import YourLibrary from "./routes/YourLibrary";
import Top50WorldPopularWorkouts from "./routes/Top50WorldPopularWorkouts";
import Top50WorldPopularWorkoutsCountry from "./routes/Top50WorldPopularWorkoutsCountry";
import Routine from "./routes/Routine";
import Diet from "./routes/Diet";
import Kcal from "./routes/Kcal";
import LandingPage from "./routes/LandingPage";
import Creator, { assignContentCreator } from "./routes/Settings/Creator";
import Personal from "./routes/Settings/Personal";

// Less accessed routes
const UpdateSettings = lazy(() => import("./routes/Settings"));
const Water = lazy(() => import("./routes/Water"));
const Create = lazy(() => import("./routes/Create"));
const NotFound = lazy(() => import("./routes/NotFound"));
const PrivacyPolicy = lazy(() => import("./routes/privacy-policy"));
const CreateRoutine = lazy(() => import("./routes/Create/Routine"));
const CreateWorkout = lazy(() => import("./routes/Create/Workout"));
const CreateExercise = lazy(() => import("./routes/Create/Exercise"));
const Premium = lazy(() => import("./routes/Premium"));

Amplify.configure(amplifyconfig);

function ProtectedCom({ children }) {
  const location = useLocation();
  const session = useUserSession();

  useEffect(() => {
    // Extract the query parameters from the URL
    const params = new URLSearchParams(location.search);
    const errorDescription = params.get("error_description");
    if (errorDescription) {
      // Decode the URL-encoded error description
      const decodedError = decodeURIComponent(errorDescription);
      toast.error(decodedError.split("error")[1]);
    }

    // Handle referral code
    const referralCode = params.get("ref");
    if (referralCode) {
      // Save the referral code to localStorage
      localStorage.setItem("referralCode", referralCode);
    }
  }, [session, location.search]);

  return (
    <AuthStyle>
      <Authenticator components={components} variation="modal">
        {({ user }) => (
          <SWRConfig
            value={{
              revalidateOnFocus: false,
              revalidateOnReconnect: false,
              revalidateIfStale: false,

              /* refreshInterval: 10000, */
              fetcher: (resource, init) =>
                fetch(resource, init).then((res) => res.json()),
            }}
          >
            <SessionProvider user={user}>{children}</SessionProvider>
          </SWRConfig>
        )}
      </Authenticator>
    </AuthStyle>
  );
}

const router = createBrowserRouter([
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/",
    element: (
      <ThemeProvider theme={theme}>
        <Suspense
          fallback={<CircularProgress size={30} sx={{ alignSelf: "center" }} />}
        >
          <LandingPage />
        </Suspense>
      </ThemeProvider>
    ),
  },
  {
    path: "/app",
    element: (
      <ThemeProvider theme={theme}>
        <ProtectedCom>
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <Root />
          </Suspense>
        </ProtectedCom>
      </ThemeProvider>
    ),
    children: [
      {
        path: "", // This will match "/app"
        element: (
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <Home />
          </Suspense>
        ),
      },
      {
        path: "diet/:customer_id", // This will match "/app/diet/:customer_id"
        element: (
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <Diet />
          </Suspense>
        ),
      },
      {
        path: "weight/:customer_id",
        element: (
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <Weight />
          </Suspense>
        ),
      },
      {
        path: "kcal/:customer_id",
        element: (
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <Kcal />
          </Suspense>
        ),
      },
      {
        path: "water/:customer_id",
        element: (
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <Water />
          </Suspense>
        ),
      },
      {
        path: "search",
        element: (
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <Search />
          </Suspense>
        ),
      },
      {
        path: "premium",
        element: (
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <Premium />
          </Suspense>
        ),
      },
      {
        path: "library",
        element: (
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <YourLibrary />
          </Suspense>
        ),
      },
      {
        path: "create",
        element: (
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <Create />
          </Suspense>
        ),
      },
      {
        path: "create/exercise",
        element: (
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <CreateExercise />
          </Suspense>
        ),
      },
      {
        path: "create/workout",
        element: (
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <CreateWorkout />
          </Suspense>
        ),
      },
      {
        path: "create/routine",
        element: (
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <CreateRoutine />
          </Suspense>
        ),
      },
      {
        path: "exercise/:exercise_id",
        element: (
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <Exercise />
          </Suspense>
        ),
      },
      {
        path: "workout/:workout_id",
        element: (
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <Workout />
          </Suspense>
        ),
      },
      {
        path: "routine/:routine_id",
        element: (
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <Routine />
          </Suspense>
        ),
      },
      {
        path: "user/:user_id",
        element: (
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <User />
          </Suspense>
        ),
      },
      {
        path: "Top50WorldPopularWorkouts",
        element: (
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <Top50WorldPopularWorkouts />
          </Suspense>
        ),
      },
      {
        path: "top50mostExecutedWorkoutsCountry/:country_id",
        element: (
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <Top50WorldPopularWorkoutsCountry />
          </Suspense>
        ),
      },
      {
        path: "settings",
        element: (
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <UpdateSettings />
          </Suspense>
        ),
        children: [
          {
            path: "creator",
            element: (
              <Suspense
                fallback={
                  <CircularProgress size={30} sx={{ alignSelf: "center" }} />
                }
              >
                <Creator />
              </Suspense>
            ),
          },
          {
            path: "personal",
            element: (
              <Suspense
                fallback={
                  <CircularProgress size={30} sx={{ alignSelf: "center" }} />
                }
              >
                <Personal />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "*",
        element: (
          <Suspense
            fallback={
              <CircularProgress size={30} sx={{ alignSelf: "center" }} />
            }
          >
            <NotFound />
          </Suspense>
        ),
      },
    ],
  },
]);

const components = {
  SignIn: {
    Header() {
      return (
        <Flex
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin={10}
          //border="1px solid red"
        >
          <Image
            alt="Amplify logo"
            src="/gymobsessivelogo.png"
            width={60}
            height={60}
            /* border="1px solid red" */
          />

          <Heading
            marginInline={11}
            level={3}
            textAlign="center"
            alignSelf="center"
            /*             border="1px solid red"
             */
          >
            Gym Obsessive
          </Heading>
        </Flex>
      );
    },
    Footer() {
      return (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <span>
            By signing in, you agree{" "}
            <a
              href="/privacy-policy"
              target="_blank"
              rel="noopener"
              style={{ color: "black" }}
            >
              {"Privacy Policy"}
            </a>
            .
          </span>
        </div>
      );
    },
  },
  SignUp: {
    Header() {
      return (
        <Flex
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin={10}
        >
          <Image
            alt="Amplify logo"
            src="/gymobsessivelogo.png"
            width={60}
            height={60}
          />

          <Heading
            marginInline={11}
            level={3}
            textAlign="center"
            alignSelf="center"
            maxWidth="80%"
          >
            Gym Obsessive
          </Heading>
        </Flex>
      );
    },
    Footer() {
      return (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <span>
            By signing up, you agree{" "}
            <a
              href="/privacy-policy"
              target="_blank"
              rel="noopener"
              style={{ color: "black" }}
            >
              {"Privacy Policy"}
            </a>
            .
          </span>
        </div>
      );
    },
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Suspense fallback={<></>}>
      <Toaster position="top-center" richColors closeButton expand={false} />
      <CssBaseline />
      <RouterProvider router={router} />
    </Suspense>
  </>
);
