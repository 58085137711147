import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";
import { IMG_BASE_URL } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const WorkoutExerciseTable = React.memo(function WorkoutExerciseTable({
  rows,
}) {
  const { t } = useTranslation(["compWorkoutExerciseTable"]);

  const navigate = useNavigate();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">#</TableCell>
            <TableCell align="left">-</TableCell>
            <TableCell align="left">{t("name")}</TableCell>
            <TableCell align="left">{t("sets")}</TableCell>
            <TableCell align="left">{t("reps")}</TableCell>
            <TableCell align="left">{t("rir")}</TableCell>
            <TableCell align="left">{t("details")}</TableCell>
            <TableCell align="left">{t("countdown")}</TableCell>
            <TableCell align="left">{t("duration")}</TableCell>
            <TableCell align="left">{t("rest")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ overflow: "scroll" }}>
          {rows && rows.length > 0 ? (
            rows.map((row, photoIndex) => (
              <TableRow
                key={row.exercise_id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  color: "black",
                  textDecoration: "none",
                  ":hover": {
                    background: "rgba(0,0,0,0.1)",
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  navigate(`/app/exercise/${row.exercise_id}`);
                }}
              >
                <TableCell align="left">{photoIndex + 1}</TableCell>
                <TableCell align="left">
                  <Box
                    component="img"
                    sx={{
                      height: "80px",
                      width: "80px",
                      borderRadius: "0.30rem",
                      boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                      objectFit: "cover",
                    }}
                    src={IMG_BASE_URL + row.photo_arr[0]}
                    alt="preview"
                  ></Box>
                </TableCell>
                <TableCell align="left">
                  <Box sx={{ maxWidth: "70px" }}>{row.name}</Box>
                </TableCell>
                <TableCell align="left">{row.execution.sets}</TableCell>
                <TableCell align="left">
                  <Box
                    sx={{
                      display: "flex",
                      minWidth: "50px",
                    }}
                  >
                    {row.execution.reps.min === row.execution.reps.max
                      ? row.execution.reps.min
                      : `${row.execution.reps.min} - ${row.execution.reps.max}`}
                  </Box>
                </TableCell>
                <TableCell align="left">{row.execution.rir}</TableCell>
                <TableCell align="left">
                  {row.execution.details || "-"}
                </TableCell>
                <TableCell align="left">{row.time.countdown} </TableCell>
                <TableCell align="left">{row.time.duration} </TableCell>
                <TableCell align="left">{row.time.rest} </TableCell>
              </TableRow>
            ))
          ) : (
            <Typography textAlign={"center"} color={"rgba(0,0,0,0.4)"}>
              {t("noCon")}
            </Typography>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default WorkoutExerciseTable;
