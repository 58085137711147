import { Box, Typography } from "@mui/material";
import React from "react";
import { IMG_BASE_URL } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Helper function to get today's workout and next workout using Vanilla JS
const getTodayAndNextWorkout = (details) => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const todayIndex = new Date().getDay(); // Get the index for today's day (0 = Sunday, 6 = Saturday)
  const today = daysOfWeek[todayIndex]; // Get the name of the current day

  let todayWorkout = null;
  let nextWorkout = null;

  for (let i = 0; i < details.length; i++) {
    if (details[i].day === today) {
      todayWorkout = details[i].workout;
      if (i < details.length - 1) {
        nextWorkout = details[i + 1].workout;
      } else if (i === details.length - 1) {
        // If it's the last workout of the week, the next workout will be the first one
        nextWorkout = details[0].workout;
      }
      break;
    }
  }

  // If todayWorkout is not found, set the first workout as today's and second as next
  if (!todayWorkout) {
    todayWorkout = details[0].workout;
    nextWorkout = details.length > 1 ? details[1].workout : null;
  }

  return { todayWorkout, nextWorkout };
};

const RoutineCard = ({ routine, isForViewUser }) => {
  const { t } = useTranslation(["compRoutineCard"]);
  const { details, name, description, photo_url, routine_id } = routine;
  const navigate = useNavigate();

  // Get the workout for today and the next workout
  const { todayWorkout, nextWorkout } = getTodayAndNextWorkout(details);

  return (
    <Box
      sx={{
        width: {
          xs: "100%",
          md: "400px",
        },
      }}
    >
      <Typography variant="h5" fontWeight="bold" mt={2}>
        <Typography variant="h5" fontWeight="bold" mt={2}>
          {t("routine")}
        </Typography>
      </Typography>
      <Box
        sx={{
          boxShadow: "0 25px 50px 0px rgba(0,0,0,0.15)",
          overflow: "hidden",
          borderRadius: "0.3rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            boxShadow: "0 25px 50px 0px rgba(0,0,0,0.15)",
            width: "100%",
            display: "flex",
            gap: 2,
            overflow: "hidden",
            ":hover": {
              cursor: "pointer",
            },
            alignItems: "center",
          }}
          onClick={() => {
            navigate("/app/routine/" + routine_id);
          }}
        >
          {/* Routine Header */}
          <Box
            component="img"
            src={IMG_BASE_URL + photo_url}
            alt={name}
            sx={{
              width: "100%",
              maxWidth: "100px",
              height: "100px",
              objectFit: "cover",
            }}
          />
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{
                overflow: "hidden", // Ensure the overflow is hidden
                textOverflow: "ellipsis", // Add ellipsis when text overflows
                whiteSpace: "nowrap", // Prevent text from wrapping
              }}
            >
              {name}
            </Typography>
            <Typography variant="body1">
              {details.length + t("workouts")}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                overflow: "hidden", // Ensure the overflow is hidden
                textOverflow: "ellipsis", // Add ellipsis when text overflows
                whiteSpace: "nowrap", // Prevent text from wrapping
              }}
            >
              {description}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column" },
            p: 2,
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            {t("todayWor")}
          </Typography>
          {/* Today's Workout */}
          {todayWorkout && (
            <Box
              sx={{
                boxShadow: "0 25px 50px 0px rgba(0,0,0,0.15)",
                borderRadius: "0.30rem",
                overflow: "hidden",
                width: "100%",
                display: "flex",
                gap: 2,
                maxWidth: "400px",
                mb: 1,
                height: "70px",

                ":hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                navigate("/app/workout/" + todayWorkout.workout_id);
              }}
            >
              {/* Routine Header */}
              <Box
                component="img"
                src={IMG_BASE_URL + todayWorkout.photo_url}
                alt={name}
                sx={{
                  width: "100%",
                  maxWidth: "70px",
                  height: "70px",
                  objectFit: "cover",
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{
                    overflow: "hidden", // Ensure the overflow is hidden
                    textOverflow: "ellipsis", // Add ellipsis when text overflows
                    whiteSpace: "nowrap", // Prevent text from wrapping
                  }}
                >
                  {todayWorkout.name}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    overflow: "hidden", // Ensure the overflow is hidden
                    textOverflow: "ellipsis", // Add ellipsis when text overflows
                    whiteSpace: "nowrap", // Prevent text from wrapping
                  }}
                >
                  {todayWorkout.description}
                </Typography>
              </Box>
            </Box>
          )}
          <Typography variant="p" fontWeight="bold">
            {t("next")}
          </Typography>
          {/* Today's Workout */}
          {nextWorkout && (
            <Box
              sx={{
                boxShadow: "0 25px 50px 0px rgba(0,0,0,0.15)",
                borderRadius: "0.30rem",
                overflow: "hidden",
                width: "100%",
                display: "flex",
                gap: 2,
                maxWidth: "400px",
                height: "60px",
                ":hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                navigate("/app/workout/" + nextWorkout.workout_id);
              }}
            >
              {/* Routine Header */}
              <Box
                component="img"
                src={IMG_BASE_URL + nextWorkout.photo_url}
                alt={name}
                sx={{
                  width: "100%",
                  maxWidth: "60px",
                  height: "60px",
                  objectFit: "cover",
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{
                    overflow: "hidden", // Ensure the overflow is hidden
                    textOverflow: "ellipsis", // Add ellipsis when text overflows
                    whiteSpace: "nowrap", // Prevent text from wrapping
                  }}
                >
                  {nextWorkout.name}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    overflow: "hidden", // Ensure the overflow is hidden
                    textOverflow: "ellipsis", // Add ellipsis when text overflows
                    whiteSpace: "nowrap", // Prevent text from wrapping
                  }}
                >
                  {nextWorkout.description}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RoutineCard;
