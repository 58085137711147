import { useNavigate, useParams } from "react-router-dom";
import { validUuid, IMG_BASE_URL } from "../../utils";
import { toast } from "sonner";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import useMuscles from "../../hooks/useMuscles";
import useExercise from "../../hooks/useExercise";
import useOneExerciseEce from "../../hooks/useOneExerciseExecution";
import TradingGraph from "../../components/TradingGraph";
import { useEffect, useState } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import MuscleTable from "../../components/ExerciseMuscleTable";
import "swiper/swiper-bundle.css";
import MediaSlider from "../../components/MediaSlider";
import { deleteExercise } from "../../utils/exerciseUtils";
import ExerciseMenu from "./ExerciseMenu";
import { useTranslation } from "react-i18next";

const Exercise = () => {
  const { t } = useTranslation(["exercise"]);

  const { muscles } = useMuscles();

  const { exercise_id } = useParams();
  const navigate = useNavigate();

  if (!validUuid(exercise_id)) {
    toast.error(
      `The exercise id "${exercise_id}" is incorrect. The correct format is: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.`
    );
    navigate("/app");
  }

  const {
    userSession,
    exercise,
    like,
    likeDisabled,
    handleLikeExercise,
    handleDislikeExercise,
  } = useExercise({
    exercise_id,
  });
  console.log("exercise:", exercise);
  const { exerciseExecution } = useOneExerciseEce({
    exercise_id,
  });

  const [exerciseWeight, setExerciseWeight] = useState(false);

  useEffect(() => {
    if (exerciseExecution && Object.keys(exerciseExecution).length > 0) {
      setExerciseWeight(
        Object.keys(exerciseExecution).sort((a, b) => b - a)[0]
      );
    }
  }, [exerciseExecution]);

  console.log("exerciseWeight: ", exerciseWeight, exerciseExecution);

  const handleDeleteExercise = async () => {
    try {
      const promise = deleteExercise({ exercise_id, jwt: userSession.jwt });
      toast.promise(promise, {
        loading: "Deleting exercise...",
        success: (updatedStatus) => `Exercise deleted succesfully.`,
        error: (err) => `Error creating exercise.`,
      });
      promise.then(() => {
        userSession.deleteAllTable(exercise_id, "exercise_id");
        navigate("/app");
      });
    } catch (error) {
      console.error("Error creating exercise" + error);
    }
  };

  if (!exercise || !muscles || !exerciseExecution) return <CircularProgress />;

  let dataMuscles = JSON.parse(exercise.muscle_details).map((musclemap) => {
    let dataPaired = muscles.filter(
      (mus) => mus.muscle_id === musclemap.muscle_id
    )[0];
    console.log("dataPaired ", dataPaired);
    return {
      ...dataPaired,
      id: musclemap.muscle_id,
      label: dataPaired.name,
      value: musclemap.percentaje,
    };
  });
  console.log("dataMuscles > ", dataMuscles);
  console.log("Exercise execution: ", exerciseExecution, exerciseWeight);
  console.log("render");
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        overflowY: "auto",
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box
          component={"img"}
          src={IMG_BASE_URL + exercise.photo_arr[0]}
          width={200}
          height={200}
          m={1}
          sx={{
            borderRadius: "0.30rem",
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
            marginX: { xs: "auto", sm: "0" },
            objectFit: "cover",
          }}
        ></Box>
        <Box ml={2}>
          <Typography variant="h2" fontWeight={"bold"}>
            {exercise.name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            pb={1}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {userSession.userSub !== exercise.owner_id &&
                (like ? (
                  <FavoriteIcon
                    sx={{
                      ":hover": { cursor: "pointer", transform: "scale(0.95)" },
                      color: "rgba(225, 8, 8)",
                      opacity: likeDisabled && "0.3",
                    }}
                    fontSize="large"
                    onClick={handleDislikeExercise}
                  />
                ) : (
                  <FavoriteBorderIcon
                    sx={{
                      ":hover": { cursor: "pointer", transform: "scale(0.95)" },
                      opacity: likeDisabled && "0.3",
                    }}
                    fontSize="large"
                    onClick={handleLikeExercise}
                  />
                ))}
              <Typography ml={0.5}>{exercise.likes_count} likes</Typography>{" "}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography fontSize={15} mr={0.5}>
                    {t("createdBy")}
                  </Typography>
                  <Typography
                    fontSize={15}
                    onClick={() => navigate(`/app/user/${exercise.owner_id}`)}
                    sx={{
                      textDecoration: "none",
                      color: "black",
                      ":hover": {
                        textDecoration: "underline",
                        cursor: "pointer",
                      },
                    }}
                  >
                    {exercise.username}
                  </Typography>
                  {exercise.verified && (
                    <VerifiedIcon
                      sx={{
                        ml: 0.3,
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  )}
                </Box>
              </Box>{" "}
            </Box>
          </Box>
        </Box>
      </Box>
      {userSession.userSub === exercise.owner_id && (
        <ExerciseMenu
          userSession={userSession}
          handleDeleteExercise={handleDeleteExercise}
          exercise={exercise}
        />
      )}
      {exerciseWeight &&
        exerciseExecution &&
        Array.isArray(exerciseExecution[exerciseWeight]) &&
        exerciseExecution[exerciseWeight].length > 0 && (
          <Box
            sx={{
              width: "100%",
              p: 2,
              borderRadius: "0.30rem",
              boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {t("strenthProgress", { WEIGHT: parseFloat(exerciseWeight) })}
            </Typography>
            <Box
              sx={{
                width: "100%",
                height: "30px",
                display: "flex",
                gap: 1,
                mt: 2,
              }}
            >
              {Object.keys(exerciseExecution)
                .sort((a, b) => b - a)
                .map((e, i) => (
                  <Box
                    key={i}
                    onClick={() => {
                      setExerciseWeight(e);
                    }}
                    sx={{
                      px: 1,
                      background: "black",
                      color: "white",
                      fontWeight: "bold",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0.3rem",
                      overflowX: "auto",
                      ":hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    {parseFloat(e)} Kg
                  </Box>
                ))}
            </Box>
            <Box sx={{ width: "100%", height: "300px", zIndex: 300 }}>
              <TradingGraph data={exerciseExecution[exerciseWeight]} />
            </Box>
          </Box>
        )}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", lg: "1fr 1fr" },
          gap: 4,
          p: 2,
        }}
      >
        <Box
          sx={{
            width: "100%",
            overflowX: "auto",
          }}
        >
          <Typography variant="h6" fontWeight="bold" my={2}>
            {t("description")}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: exercise.description ? "" : "rgba(0,0,0,0.5)" }}
          >
            {exercise.description ? exercise.description : "No desc."}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Typography variant="h6" fontWeight="bold" my={2}>
              {t("muscleTable")}
            </Typography>

            <MuscleTable muscles={dataMuscles} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: { xs: "center", sm: "flex-start" },
            width: "100%",
            overflowX: "auto",
          }}
        >
          <MediaSlider exercise={exercise} />
        </Box>
      </Box>
    </Box>
  );
};

export default Exercise;
