import React, { useState, useCallback } from "react";
import { Box, Button, Typography, Slider } from "@mui/material";
import { toast } from "sonner";
import { useUserJwt, useUserSession } from "../../context/SessionProvider";
import { API_BASE_URL } from "../../utils";
import { useTranslation } from "react-i18next";

const CreateEditHeight = ({ height: prevHeight, optional }) => {
  const { t } = useTranslation(["compCreateEditHeight"]);
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState(prevHeight | 160); // default to 170 cm
  const session = useUserSession();
  const jwt = useUserJwt();

  const updateHeight = useCallback(
    async (newHeight) => {
      setLoading(true);
      if (newHeight && newHeight !== prevHeight) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + jwt);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ height: newHeight });

        const requestOptions = {
          method: "PATCH",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const promise = new Promise((resolve, reject) => {
          fetch(`${API_BASE_URL}/customer/height`, requestOptions)
            .then((response) => {
              if (!response.ok) {
                throw new Error("There was an error with the height update");
              }
              session.change.height(newHeight); // Assuming session handles height updates
              resolve(newHeight);
            })
            .catch((error) => {
              reject(error);
            });
        });

        toast.promise(promise, {
          loading: t("load"),
          success: t("suc"),
          error: (err) => `Error: ${JSON.stringify(err?.errMsg || err)}.`,
        });
      } else {
        toast.error(`The selected height is invalid.`);
      }
      setLoading(false);
    },
    [prevHeight, jwt, session]
  );

  const handleSliderChange = (event, newValue) => {
    setHeight(newValue);
  };

  if (optional) {
    return null;
  }

  return (
    <Box
      sx={{
        borderRadius: "0.30rem",
        boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
        p: 1,
      }}
    >
      <Typography variant="h5" fontWeight="bold" marginY={1}>
        {t("height")}
      </Typography>
      <Typography variant="body1">{t("selectHe", { height })}</Typography>

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
        <Slider
          value={height}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
          min={100}
          max={220}
          valueLabelDisplay="auto"
          disabled={loading}
          sx={{ width: "290px" }}
        />

        <Button
          size="medium"
          onClick={() => updateHeight(height)}
          sx={{
            px: 2,
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
            color: "white",
            background: "black",
            ":hover": { background: "black", transform: "scale(0.95)" },
          }}
        >
          {t("save")}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateEditHeight;
