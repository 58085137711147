import { Box, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IMG_BASE_URL } from "../../utils";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useTranslation } from "react-i18next";

const ItemCard = ({ item }) => {
  const { t } = useTranslation(["compItemCard"]);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  let type;
  let typeLink;
  let id;

  if ("workout_id" in item) {
    type = t("workout");
    typeLink = "workout";
    id = item.workout_id;
  } else if ("exercise_id" in item) {
    type = t("exercise");
    typeLink = "exercise";
    id = item.exercise_id;
  } else if ("routine_id" in item) {
    type = t("routine");
    typeLink = "routine";
    id = item.routine_id;
  } else if ("customer_id" in item) {
    type = t("user");
    typeLink = "user";
    id = item.customer_id;
  } else if ("id" in item) {
    type = t(item.type);
    typeLink = item.type;
    id = item.id;
  }

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "70px",
        borderRadius: "0.30rem",
        boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
        gap: 1,
        mb: 1,
        p: 1,
        display: "flex",
        textTransform: "none",
        alignItems: "center",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",

        color: "black",
        ":hover": {
          bgcolor: "rgba(0,0,0,0.02)",
          cursor: "pointer",
          transform: "scale(0.95)",
        },
        ":focus": {
          color: "black",
        },
        ":active": {
          color: "black",
        },
        textDecoration: "none",
      }}
      onClick={() => navigate(`/app/${typeLink}/` + id)}
    >
      {item.photo_url || item.photo_arr || item.photo ? (
        <Box
          component="img"
          src={
            IMG_BASE_URL + (item.photo || item.photo_url || item.photo_arr[0])
          }
          sx={{
            width: "50px",
            height: "50px",
            minWidth: "50px",
            minHeight: "50px",
            borderRadius: "0.30rem",
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
            bgcolor: "rgba(0,0,0,0.1)",
            objectFit: "cover",
            margin: { xs: "auto", md: 0 },
          }}
        ></Box>
      ) : (
        <Box
          component="img"
          src={"/noimageuser.webp"}
          sx={{
            width: "50px",
            height: "50px",
            minWidth: "50px",
            minHeight: "50px",
            borderRadius: "0.30rem",
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
            bgcolor: "rgba(0,0,0,0.1)",
            objectFit: "cover",
            margin: { xs: "auto", md: 0 },
          }}
        ></Box>
      )}

      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: {
            xs: "block",
            sm: !pathname.includes("library") && "none",
            md: "block",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {type === "user" ? (
            <Typography fontSize={17}>
              {item.username.slice(0, 23)}
              {item.verified && (
                <VerifiedIcon sx={{ ml: 0.3, width: "15px" }} />
              )}
            </Typography>
          ) : (
            item.name.slice(0, 23)
          )}
        </Box>
        <Box sx={{ display: "flex", gap: 0.5 }}>
          <Typography
            fontSize={13}
            sx={{
              color: "black",
            }}
          >
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </Typography>

          {typeLink !== "user" && (
            <Box
              onClick={(e) => {
                e.stopPropagation();
                navigate("/app/user/" + item.owner_id);
              }}
              sx={{
                display: "flex",
                color: "black",
                ":hover": {
                  textDecoration: "underline",
                },
                ":focus": {
                  color: "black",
                },
                ":active": {
                  color: "black",
                },
                textDecoration: "none",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography fontSize={13} mr={0.5}>
                  {" | "}
                </Typography>

                <Typography fontSize={13}>{item.username}</Typography>
                {item.verified && (
                  <VerifiedIcon
                    sx={{ ml: 0.3, width: "15px", height: "15px" }}
                  />
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ItemCard;
