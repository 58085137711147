import { Link, useNavigate, useParams } from "react-router-dom";
import { useUserSession } from "../../context/SessionProvider";
import { API_BASE_URL, IMG_BASE_URL, validUuid } from "../../utils";
import { toast } from "sonner";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import useRoutine from "../../hooks/useRoutine";
import RoutineWorkoutTable from "../../components/RoutineWorkoutTable";
import VerifiedIcon from "@mui/icons-material/Verified";
import useLastRoutineExecution from "../../hooks/useMyLastRoutine";
import { deleteRoutine } from "../../utils/routineUtils";
import RoutineMenu from "./RoutineMenu";
import { useTranslation } from "react-i18next";

// Function to execute the routine
async function executeRoutine({ routine_id, jwt, country_id }) {
  console.log("execute routine executed: ");
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwt);
    myHeaders.append("Content-Type", "application/json");

    const body = JSON.stringify({
      country_id, // Include the country_id in the request body
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: body, // Add the body to the request options
      redirect: "follow",
    };

    const res = await fetch(
      API_BASE_URL + "/executeRoutine/" + routine_id,
      requestOptions
    );

    const resJson = await res.json();
    console.log("executed routine res: ", resJson);

    // Success response
    if (resJson.status === "ok") {
      return resJson.data;
    } else {
      throw new Error("The routine was not found.");
    }
  } catch (error) {
    console.error("Error executing routine", error);
    throw new Error("The routine was not found");
  }
}

const Routine = () => {
  const { t } = useTranslation(["routine"]);
  const { routine_id } = useParams();
  const navigate = useNavigate();
  const userSession = useUserSession();

  const { lastRoutineExecution, lastRoutineExecutionIsLoading, mutate } =
    useLastRoutineExecution({
      customer_id: userSession?.userSub,
    });

  const {
    data,
    isLoading,
    likeDisabled,
    handleLikeRoutine,
    handleDislikeRoutine,
  } = useRoutine({
    routine_id,
  });

  const { routine, like, workouts } = data || {};

  if (!validUuid(routine_id)) {
    toast.error(
      `The routine id "${routine_id}" is incorrect. The correct format is: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.`
    );
    navigate("/app");
  }

  const handleDeleteRoutine = async () => {
    try {
      const promise = deleteRoutine({ routine_id, jwt: userSession.jwt });
      toast.promise(promise, {
        loading: "Deleting routine...",
        success: (updatedStatus) => `routine deleted succesfully.`,
        error: (err) => `Error creating routine.`,
      });
      promise.then(() => {
        userSession.deleteAllTable(routine_id, "routine_id");
        navigate("/app");
      });
    } catch (error) {
      console.error("Error deleting routine" + error);
    }
  };

  if (isLoading || !workouts || !routine || lastRoutineExecutionIsLoading) {
    return <CircularProgress />;
  }

  // Function to handle routine execution with toast.promise
  const handleExecuteRoutine = async () => {
    const promise = new Promise(async (res, rej) => {
      try {
        await executeRoutine({
          routine_id,
          jwt: userSession.jwt,
          country_id: userSession.customer.country_id, // Assuming country_id is part of userSession
        });
        await mutate();
        res();
      } catch (error) {
        console.log("error execute royutine ", error);
        rej();
      }
    });
    toast.promise(promise, {
      loading: "Assigning the routine...",
      success: "Routine assigned successfully!",
      error: "Failed to assign the routine. Please try again.",
    });
  };

  return (
    <Box sx={{ width: "100%", height: "100%", p: 2 }}>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
        <Box
          component={"img"}
          src={IMG_BASE_URL + routine.photo_url}
          alt="routine image"
          width={200}
          height={200}
          m={1}
          sx={{
            bgcolor: "rgba(0,0,0,0.1)",
            alignSelf: "center",
            borderRadius: "0.30rem",
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
            objectFit: "cover",
          }}
        ></Box>
        <Box ml={2}>
          <Typography variant="h2" fontWeight={"bold"}>
            {routine.name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            pb={1}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {userSession.userSub !== routine.owner_id &&
                (like ? (
                  <FavoriteIcon
                    sx={{
                      ":hover": {
                        cursor: !likeDisabled && "pointer",
                        transform: !likeDisabled && "scale(0.95)",
                      },
                      color: "rgba(225, 8, 8)",
                      opacity: likeDisabled && "0.3",
                    }}
                    fontSize="large"
                    onClick={handleDislikeRoutine}
                  />
                ) : (
                  <FavoriteBorderIcon
                    sx={{
                      ":hover": {
                        cursor: !likeDisabled && "pointer",
                        transform: !likeDisabled && "scale(0.95)",
                      },
                      opacity: likeDisabled && "0.3",
                    }}
                    fontSize="large"
                    onClick={handleLikeRoutine}
                  />
                ))}
              <Typography ml={0.5}>{routine.likes_count} likes</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography fontSize={15} mr={0.5}>
                    {t("createdBy")}
                  </Typography>
                  <Typography
                    fontSize={15}
                    component={Link}
                    to={`/app/routine/${routine.owner_id}`}
                    sx={{
                      color: "black",
                      ":hover": { textDecoration: "underline" },
                    }}
                  >
                    {routine.username}
                  </Typography>
                  {routine.verified && (
                    <VerifiedIcon
                      sx={{
                        ml: 0.3,
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {userSession.userSub === routine.owner_id && (
        <RoutineMenu
          userSession={userSession}
          handleDeleteRoutine={handleDeleteRoutine}
          routine={routine}
        />
      )}

      <Box my={2}>
        <Button
          variant="contained"
          sx={{
            textDecoration: "none",
            textTransform: "none",
          }}
          disabled={lastRoutineExecution?.routine_id === routine_id}
          onClick={handleExecuteRoutine} // Add this button for executing routine
        >
          {t("useRoutine")}
        </Button>
      </Box>
      <Typography variant="h5" fontWeight="bold" mt={2}>
        {t("workoutTable")}
      </Typography>
      <RoutineWorkoutTable rows={workouts} />
    </Box>
  );
};

export default Routine;
