import { Box, CircularProgress, Typography } from "@mui/material";
import useCustomerWeight from "../../hooks/useCustomerWeight";
import TradingGraph from "../../components/TradingGraph";
import { useParams } from "react-router-dom";
import CreateEditWeight from "../../components/CreateEditWeight";
import { useUserSession } from "../../context/SessionProvider";
import { useTranslation } from "react-i18next";

const Weight = () => {
  const { t } = useTranslation(["weight"]);
  const { customer_id } = useParams();

  const session = useUserSession();

  const { customerWeightArr, isLoading } = useCustomerWeight({ customer_id });

  if (!customerWeightArr || isLoading) {
    return <CircularProgress size={30} />;
  }
  console.log("customerWeightArr, isLoading_ ", customerWeightArr, isLoading);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        px: 3,
      }}
    >
      <Typography variant="h2">{t("weight")}</Typography>
      <CreateEditWeight weight={session?.customer?.weight} />

      {Array.isArray(customerWeightArr) && customerWeightArr.length > 0 ? (
        <TradingGraph data={customerWeightArr} />
      ) : (
        <Typography>{t("noData")}</Typography>
      )}
    </Box>
  );
};

export default Weight;
