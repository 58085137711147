import React, { useState, useCallback } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { toast } from "sonner";
import { useUserJwt, useUserSession } from "../../context/SessionProvider";
import { API_BASE_URL } from "../../utils";
import { useTranslation } from "react-i18next";

const CreateEditGender = ({ gender: prevGender, optional }) => {
  const { t } = useTranslation(["compCreateEditGender"]);
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState(prevGender || "");
  const session = useUserSession();
  const jwt = useUserJwt();

  const updateGender = useCallback(
    async (newGender) => {
      setLoading(true);
      if (
        newGender &&
        newGender !== prevGender &&
        ["M", "F"].includes(newGender)
      ) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + jwt);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ gender: newGender });

        const requestOptions = {
          method: "PATCH",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const promise = new Promise((resolve, reject) => {
          fetch(`${API_BASE_URL}/customer/gender`, requestOptions)
            .then((response) => {
              if (!response.ok) {
                throw new Error("There was an error with the gender");
              }
              session.change.gender(newGender); // Assuming session handles birthdate
              resolve(newGender);
            })
            .catch((error) => {
              reject(error);
            });
        });

        toast.promise(promise, {
          loading: "Updating gender...",
          success: "Gender successfully updated.",
          error: (err) => `Error: ${JSON.stringify(err?.errMsg || err)}.`,
        });
      } else {
        toast.error(`The selected gender is invalid.`);
      }
      setLoading(false);
    },
    [prevGender, jwt, session]
  );

  if (optional) {
    return null;
  }

  return (
    <Box
      sx={{
        borderRadius: "0.30rem",
        boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
        p: 1,
      }}
    >
      <Typography variant="h5" fontWeight="bold" marginY={1}>
        {t("gender")}
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
        <FormControl sx={{ width: "300px" }} error={!gender} required>
          <InputLabel id="gender-select-label">{t("gender")}</InputLabel>
          <Select
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            disabled={loading}
            labelId="gender-select-label"
            label={t("gender")}
          >
            <MenuItem key={"M"} value={"M"}>
              {t("male")}
            </MenuItem>
            <MenuItem key={"F"} value={"F"}>
              {t("female")}
            </MenuItem>
          </Select>
        </FormControl>
        <Button
          size="medium"
          onClick={() => updateGender(gender)}
          sx={{
            px: 2,
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
            color: "white",
            background: "black",
            ":hover": { background: "black", transform: "scale(0.95)" },
          }}
        >
          {t("save")}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateEditGender;
