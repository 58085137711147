import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const FreeTrialButton = () => {
  const { t } = useTranslation(["premium"]);
  return (
    <Box
      component={Link}
      to={"/app/premium"}
      sx={{
        position: "relative",
        borderRadius: "0.30rem",
        boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
        background:
          "radial-gradient(circle, rgba(0,0,0,0.8435749299719888) 0%, rgba(242,211,0,1) 100%)",
        color: "white",
        height: "50px",
        display: "flex",
        alignItems: "center",
        fontWeight: "bold",
        px: 3,
        justifyContent: "center",
        textAlign: "center",
        overflow: "hidden",
        "::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background:
            "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%)",
          transform: "translateX(-100%)",
          animation: "reflex 10s infinite", // Infinite reflex animation
        },
        cursor: "pointer",
        textDecoration: "none",
        ":hover": {
          transform: "scale(0.95)",
        },
        textWrap: "nowrap",
      }}
    >
      {t("buttonHome")}
    </Box>
  );
};

export default FreeTrialButton;
