import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow"; // Import PlayArrowIcon
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Thumbs } from "swiper/modules";
import "swiper/swiper-bundle.css";
import { IMG_BASE_URL } from "../../utils";
import "swiper/css/navigation"; // Import navigation styles
import "swiper/css/thumbs"; // Import thumbs styles

// Component to show media slider (images + video)
const MediaSlider = ({ exercise }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null); // For controlling thumbnails

  // Combine image and video into one array for the slider
  const mediaArr = [
    ...(exercise.photo_arr ? exercise.photo_arr.slice(1) : []),
    ...(exercise.video_arr ? [exercise.video_arr[0]] : []),
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        pb: 3,
      }}
    >
      <Typography variant="h4" sx={{ mb: 2 }}>
        Media
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",

          alignItems: "flex-start",
          justifyContent: "flex-start",
          pb: 3,
          gap: 1,
        }}
      >
        {/* Thumbnails for Preview */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            borderRadius: "0.3rem",
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.1)",
            px: 1,
          }}
        >
          <Swiper
            onSwiper={setThumbsSwiper}
            slidesPerView={mediaArr.length}
            freeMode={true}
            watchSlidesProgress
            direction="vertical" // Vertical layout for previews
            style={{
              width: "80px",
              display: "flex",
              height: "auto",
              alignItems: "center",
            }}
          >
            {mediaArr.map((item, index) => (
              <SwiperSlide key={index} style={{ marginBottom: "5px" }}>
                {item.includes(".mp4") ? (
                  <Box
                    sx={{
                      position: "relative",
                      width: "80px",
                      height: "80px",
                      borderRadius: "0.3rem",
                      ":hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Box
                      component="video"
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "0.3rem",
                      }}
                    >
                      <source src={IMG_BASE_URL + item} type="video/mp4" />
                      Your browser does not support the video tag.
                    </Box>
                    {/* Play icon overlay */}
                    <PlayArrowIcon
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "1.5rem",
                        color: "white",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        borderRadius: "50%",
                        padding: "0.25rem",
                      }}
                    />
                  </Box>
                ) : (
                  <Box
                    component="img"
                    src={IMG_BASE_URL + item}
                    alt={`Media ${index}`}
                    sx={{
                      width: "80px",
                      height: "80px",
                      objectFit: "cover",
                      borderRadius: "0.3rem",
                      boxShadow: "0 25px 50px -12px rgba(0,0,0,0.1)",
                      ":hover": {
                        cursor: "pointer",
                      },
                    }}
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>

        {/* Media Slider */}
        <Box>
          <Swiper
            modules={[Navigation, Pagination, Thumbs]}
            navigation
            pagination={{ clickable: true }}
            thumbs={{ swiper: thumbsSwiper }} // Connect with the thumbs (preview) swiper
            spaceBetween={10}
            slidesPerView={1}
            style={{
              maxWidth: "450px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              borderRadius: "0.3rem",
              boxShadow: "0 25px 50px -12px rgba(0,0,0,0.15)",
            }}
          >
            {/* Map through media (images and video) */}
            {mediaArr.map((item, index) => (
              <SwiperSlide key={index}>
                {item.includes(".mp4") ? (
                  <Box
                    component="video"
                    controls
                    sx={{
                      maxWidth: "450px",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    <source src={IMG_BASE_URL + item} type="video/mp4" />
                    Your browser does not support the video tag.
                  </Box>
                ) : (
                  <Box
                    component="img"
                    src={IMG_BASE_URL + item}
                    alt={`Media ${index}`}
                    sx={{
                      maxWidth: "450px",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
      {/* Fallback message if no media */}
      {mediaArr.length === 0 && (
        <Typography
          variant="subtitle1"
          my={3}
          sx={{
            alignSelf: "start",
            color: exercise.description ? "" : "rgba(0,0,0,0.5)",
          }}
        >
          No content.
        </Typography>
      )}
    </Box>
  );
};

export default MediaSlider;
