import useSWR from "swr";
import { useMemo } from "react";
import { readMySubscriptions } from "../utils/subscriptionLogic";
import { useUserJwt } from "../context/SessionProvider";

const useSubscriptions = () => {
  const jwt = useUserJwt();

  const { data, isLoading, error, mutate } = useSWR(
    { url: `readMySubscriptions`, jwt },
    readMySubscriptions
  );

  const mySubscriptionsMemoized = useMemo(() => data, [data]);

  return {
    mySubscriptions: mySubscriptionsMemoized,
    isLoading,
    error,
    mutate,
  };
};

export default useSubscriptions;
