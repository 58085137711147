import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { toast } from "sonner";
import { useUserJwt, useUserSession } from "../../context/SessionProvider";
import { API_BASE_URL } from "../../utils";
import { useTranslation } from "react-i18next";

const CreateEditCountry = ({ country_id, optional }) => {
  const { t } = useTranslation(["compCreateEditCountry"]);
  const session = useUserSession();
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState(country_id);
  const [countries, setCountries] = useState([]);
  const jwt = useUserJwt();
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/country/readCountries`);
        const result = await response.json();
        setCountries(result.data);
      } catch (error) {
        toast.error("Failed to fetch countries.");
      }
    };

    fetchCountries();
  }, []);

  const updateCountry = useCallback(
    async (newCountry, countries) => {
      setLoading(true);
      if (newCountry && newCountry !== country_id) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + jwt);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ country_id: newCountry });

        const requestOptions = {
          method: "PATCH",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const promise = new Promise((resolve, reject) => {
          fetch(`${API_BASE_URL}/customer/country`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
              resolve(result);
              console.log("answer ", result);
              console.log("countries ", countries);
              let nameObj = countries.filter(
                (c) => c.country_id === newCountry
              )[0];
              console.log("name obj", nameObj);
              console.log("name ", nameObj.name);
              session.change.country(newCountry, nameObj.name);
            })
            .catch((error) => {
              reject(error);
            });
        });

        toast.promise(promise, {
          loading: "Editing country...",
          success: "Country successfully edited.",
          error: (err) => `Error: ${JSON.stringify(err?.errMsg || err)}.`,
        });
      } else {
        toast.error(`The selected country is invalid.`);
      }
      setLoading(false);
    },
    [country_id, jwt]
  );

  if (optional) {
    return null;
  }

  return (
    <Box
      sx={{
        borderRadius: "0.30rem",
        boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
        p: 1,
      }}
    >
      <Typography variant="h5" fontWeight="bold" marginY={1}>
        {t("country")}
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
        <FormControl sx={{ width: "300px" }} error={!country} required>
          <InputLabel id="demo-simple-select-label">{t("country")}</InputLabel>
          <Select
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            disabled={loading || countries.length === 0}
            labelId="demo-simple-select-label"
            label={t("country")}
          >
            {countries.map((c) => (
              <MenuItem key={c.country_id} value={c.country_id}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          size="medium"
          onClick={() => updateCountry(country, countries)}
          sx={{
            px: 2,
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
            color: "white",
            background: "black",
            ":hover": { background: "black", transform: "scale(0.95)" },
          }}
        >
          {t("save")}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateEditCountry;
