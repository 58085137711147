import React, { useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";

const WorkoutMenu = ({ userSession, handleDeleteWorkout, workout }) => {
  const { t } = useTranslation(["workoutMenu"]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    handleMenuClose();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const confirmDeleteWorkout = async () => {
    handleDeleteWorkout();
    handleCloseDialog(); // Close the dialog after confirming
  };

  return (
    <Box position="fixed" top={16} right={16}>
      <IconButton
        aria-controls={anchorEl ? "workout-menu" : undefined}
        aria-haspopup="true"
        onClick={handleMenuClick}
        color="inherit"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="workout-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {userSession.userSub === workout.owner_id && (
          <MenuItem onClick={handleOpenDialog}>
            <Button
              color="error"
              variant="outlined"
              onClick={handleOpenDialog}
              fullWidth // Optional: Makes the button take full width of the MenuItem
            >
              {t("deleteWorkout")}
            </Button>
          </MenuItem>
        )}
      </Menu>

      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle> {t("confirmDeletion")}</DialogTitle>
        <DialogContent>{t("confirmDeletionText")}</DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            variant="outlined"
            color="primary"
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={confirmDeleteWorkout}
            variant="outlined"
            color="error"
          >
            {t("deleteButton")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default WorkoutMenu;
