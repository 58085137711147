import { Box, CircularProgress, Typography } from "@mui/material";

import { useParams } from "react-router-dom";
import useCustomerDiet from "../../hooks/useCustomerDiet";
import { TradingGraphStages } from "../../components/TradingGraphStages";
import CreateEditStatus from "../../components/CreateEditStatus";
import { useTranslation } from "react-i18next";

const Diet = () => {
  const { t } = useTranslation(["diet"]);
  const { customer_id } = useParams();

  const { customerDietArr, isLoading } = useCustomerDiet({ customer_id });

  if (!customerDietArr || isLoading) {
    return <CircularProgress size={30} />;
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        px: 3,
      }}
    >
      <Typography variant="h2">{t("diet")}</Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: {
            md: "flex-end",
          },
          flexDirection: {
            xs: "column",
            md: "row",
          },
          gap: 3,
        }}
      >
        <CreateEditStatus />
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Box
            component={"img"}
            src="/bear.png"
            alt="Bear for Bulk"
            sx={{ width: "40px", height: "40px" }}
          />
          <Box
            sx={{
              width: "20px",
              height: "20px",
              backgroundColor: "rgba(255, 0, 0, 0.8)",
            }}
          />
          <Box
            component={"img"}
            src="/flamenco.png"
            alt="Flamenco for Definition"
            sx={{ width: "40px", height: "40px" }}
          />
          <Box
            sx={{
              width: "20px",
              height: "20px",
              backgroundColor: "rgba(255, 0, 128, 0.5)",
            }}
          />
          <Box
            component={"img"}
            src="/chamaleon.png"
            alt="Chameleon for Recomposition"
            sx={{ width: "40px", height: "40px" }}
          />
          <Box
            sx={{
              width: "20px",
              height: "20px",
              backgroundColor: "rgba(0, 153, 51, 0.5)",
            }}
          />
        </Box>
      </Box>
      {Array.isArray(customerDietArr) && customerDietArr.length > 0 ? (
        <TradingGraphStages phases={customerDietArr} />
      ) : (
        <Typography>{t("noInfo")}</Typography>
      )}
    </Box>
  );
};

export default Diet;
