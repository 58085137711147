import { Box, CircularProgress, Typography } from "@mui/material";
import TopWorkoutTable from "../../components/TopWorkoutTable ";
import PlaceIcon from "@mui/icons-material/Place";
import useTop50WorkoutsToday from "../../hooks/useTop50CountryWorkoutsToday";
import { Link } from "react-router-dom";
import { useUserSession } from "../../context/SessionProvider";

const Top50WorldPopularWorkoutsCountry = ({ limit = 50, small }) => {
  const { workouts, country_name } = useTop50WorkoutsToday();
  const session = useUserSession();

  if (!workouts) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          p: small ? 1 : 2,
          mt: small && 2,
          width: { xs: "100%", md: "auto" },
          overflowX: "auto",
        }}
        className="scrollbar-hide"
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <PlaceIcon
            sx={{
              width: small ? "60px" : "100px",
              height: small ? "60px" : "100px",
            }}
          ></PlaceIcon>

          <Typography
            sx={{ fontSize: { xs: "20px", md: "30px", color: "black" } }}
            fontWeight="bold"
            component={small && Link}
            to={
              "/app/top50mostExecutedWorkoutsCountry/" + session.customer.country_id
            }
          >
            Top {limit} - {country_name}
          </Typography>
        </Box>

        <CircularProgress size={30} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        p: small ? 1 : 2,
        mt: small && 2,
        width: { xs: "100%", md: "auto" },
        overflowX: "auto",
      }}
      className="scrollbar-hide"
    >
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <PlaceIcon
          sx={{
            width: small ? "60px" : "100px",
            height: small ? "60px" : "100px",
          }}
        ></PlaceIcon>

        <Typography
          sx={{ fontSize: { xs: "20px", md: "30px", color: "black" } }}
          fontWeight="bold"
          component={small && Link}
          to={
            "/app/top50mostExecutedWorkoutsCountry/" + session.customer.country_id
          }
        >
          Top {limit} - {country_name}
        </Typography>
      </Box>

      {Array.isArray(workouts) && (
        <TopWorkoutTable rows={workouts.slice(0, limit)} small={small} />
      )}
    </Box>
  );
};

export default Top50WorldPopularWorkoutsCountry;
