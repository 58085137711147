import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useUserSession } from "../../../context/SessionProvider";
import { API_BASE_URL } from "../../../utils";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "sonner";
import useUser from "../../../hooks/useUser";
import ItemCard from "../../../components/ItemCard";
import CheckIcon from "@mui/icons-material/Check";
import { businessLogic } from "../../../utils/businessLogic";
import { CLIENT_ID } from "../../Premium";
import { useNavigate } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "react-i18next";
const REDIRECT_URI = "https://gymobsessive.com/app/settings/creator";

export const assignContentCreator = async (
  creator_id,
  deleteCreator = false,
  session,
  silence = false
) => {
  try {
    console.log("assign content creator executed");
    const response = await fetch(API_BASE_URL + "/assignContentCreator", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + session.jwt,
      },
      body: JSON.stringify({
        creator_id,
        deleteCreator, //true for deleting content creator | false to not
      }),
    });
    if (!response.ok) {
      throw new Error("The response was unsuccesfull");
    }
    if (deleteCreator) {
      toast.success("The content creator was deleted succesfully.");
      session.change.creator_id(null);
    } else {
      if (!silence) {
        toast.success("The content creator was assigned succesfully.");
      }
      session.change.creator_id(creator_id);
    }
    console.log("Assign creator result:", response);
    // Handle success, e.g., show a success message or update the UI
  } catch (error) {
    console.error("Error assigning content creator:", error);
    toast.error("The content creator was NOT assigned succesfully.");
  }
};

const Creator = () => {
  const { t } = useTranslation(["creatorSettings"]);
  const navigate = useNavigate();
  const session = useUserSession();
  const [search, setSearch] = useState(""); // State to track the search input
  const [creators, setCreators] = useState(null); // State to hold the search results
  const [balance, setBalance] = useState(null);

  const currentCreatorId = session?.customer?.creator_id;
  const followers_count = session?.customer?.followers_count;
  const is_user_premium = session?.customer?.premium;
  const is_content_creator = session?.customer?.is_content_creator;
  const paypal_email = session?.customer?.paypal_email;

  const { user } = useUser({ user_id: currentCreatorId });

  // Function to handle search input change
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  // Function to fetch search results from the API
  const fetchCreators = async () => {
    try {
      const response = await fetch(API_BASE_URL + "/searchCreatorsByUsername", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + session.jwt,
        },
        body: JSON.stringify({ search }),
      });
      const data = await response.json();
      console.log("fetchCreators ", data);
      setCreators(data.data || []); // Set the creators from response
    } catch (error) {
      console.error("Error fetching content creators:", error);
    }
  };

  // Function to assign the selected content creator to the user

  const handleActivateContentCreator = async () => {
    /*
      TODO: Activate content creator
   */
    try {
      const response = await fetch(API_BASE_URL + "/assignContentCreator", {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + session.jwt,
        },
        body: JSON.stringify({
          becomeCreator: true, //true for deleting content creator | false to not
        }),
      });
      if (!response.ok) {
        throw new Error("The response was unsuccesfull");
      }
      toast.success("Congratulations! You are a content creator now!");
      console.log("Assign creator result:", response);
      // Handle success, e.g., show a success message or update the UI
    } catch (error) {
      console.error("Error assigning content creator:", error);
      toast.error("The content creator request was not succesfully.");
    }
  };

  const readMyCreatorBalance = useCallback(async () => {
    try {
      const response = await fetch(API_BASE_URL + "/pay/myCreatorBalance", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + session.jwt,
        },
      });
      if (!response.ok) {
        throw new Error("The response was unsuccesfull");
      }
      const resJSON = await response.json();
      const balance = resJSON.data.balance;
      setBalance(balance);
      console.log("readMyCreatorBalance:", resJSON);
      // Handle success, e.g., show a success message or update the UI
    } catch (error) {
      console.error("Error assigning content creator:", error);
      toast.error("readMyCreatorBalance was not succesfully.");
    }
  }, [session]);

  const handleAuthRedirect = () => {
    const paypalAuthUrl = `https://www.sandbox.paypal.com/connect?flowEntry=static&client_id=${CLIENT_ID}&response_type=code&scope=openid profile email&redirect_uri=${REDIRECT_URI}`;
    window.location.href = paypalAuthUrl;
  };

  const handleDeletePaypalEmail = async () => {
    try {
      // Call the backend API to verify PayPal email
      const response = await fetch(
        `${API_BASE_URL}/pay/paypal/verificateEmail`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + session.jwt,
          },
          body: JSON.stringify({ deletePaypalEmail: true }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        toast.success("PayPal email successfully deleted.");
        setTimeout(() => {
          window.location.href = "/app/settings/creator";
        }, 1500);
      } else {
        console.error("Error verifying PayPal email:", data.errMsg);
        toast.error("Failed to verify PayPal email.");
      }
    } catch (error) {
      console.error("Error making request to backend:", error);
      toast.error("An error occurred while verifying your PayPal email.");
    }
  };

  const withdrawMoney = async () => {
    //

    try {
      // Call the backend API to verify PayPal email
      const response = await fetch(
        `${API_BASE_URL}/pay/paypal/withdrawBalance`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + session.jwt,
          },
        }
      );

      if (response.ok) {
        toast.success("Balance withdrawn succesfully.");
        setTimeout(() => {
          window.location.href = "/app/settings/creator";
        }, 1500);
      } else {
        const data = await response.json();
        console.error("Error withdrawing balance:", data.errMsg);
        toast.error(data.errMsg || "Error withdrawing balance.");
      }
    } catch (error) {
      console.error("An error occurred withdrawing your balance:", error);
      toast.error("An error occurred withdrawing your balance.");
    }
  };

  useEffect(() => {
    if (is_content_creator) {
      readMyCreatorBalance();
    }
  }, [is_content_creator, readMyCreatorBalance]);

  useEffect(() => {
    // Function to handle the callback and verify email with the backend
    const handlePayPalCallback = async () => {
      // Extract 'code' from the URL
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      if (code) {
        try {
          // Call the backend API to verify PayPal email
          const response = await fetch(
            `${API_BASE_URL}/pay/paypal/verificateEmail`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + session.jwt,
              },
              body: JSON.stringify({ code }),
            }
          );

          const data = await response.json();

          if (response.ok) {
            toast.success("PayPal email successfully verified and updated.");
          } else {
            console.error("Error verifying PayPal email:", data.errMsg);
            toast.error("Failed to verify PayPal email.");
          }
        } catch (error) {
          console.error("Error making request to backend:", error);
          toast.error("An error occurred while verifying your PayPal email.");
        }
        setTimeout(() => {
          window.location.href = "/app/settings/creator";
        }, 1500);
      }
    };

    // Call the function when the component mounts
    handlePayPalCallback();
  }, [navigate, session?.jwt]);

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 4, pb: 3 }}>
      <Box>
        <Typography sx={{ color: "black" }} variant="h4" mt={3}>
          {t("supporCreator")}
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          {/* Search Content Creator Feature */}
          {currentCreatorId &&
            (!user ? (
              <CircularProgress size={30} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "0.30rem",
                  boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                  p: 1,
                  my: 3,
                }}
              >
                <Typography sx={{ color: "black" }} variant="h4">
                  {t("content")}
                </Typography>
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  {user && <ItemCard item={user} />}
                  <Button
                    onClick={() => {
                      assignContentCreator("", true, session);
                      setSearch("");
                      setCreators(null);
                    }}
                    variant="contained"
                    color="primary"
                    sx={{
                      height: "55px",
                    }}
                  >
                    {t("delete")}
                  </Button>
                </Box>
              </Box>
            ))}
          {!creators && !currentCreatorId && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                borderRadius: "0.30rem",
                boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                p: 1,
                my: 2,
              }}
            >
              <Typography variant="h5" fontWeight="bold">
                {t("enterUsr")}
              </Typography>

              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    width: "350px",
                    alignItems: "center",
                  }}
                >
                  {/* Search Input */}
                  <TextField
                    label={t("searchByUsr")}
                    variant="outlined"
                    value={search}
                    onChange={handleSearchChange}
                    fullWidth
                    sx={{ maxWidth: "300px" }}
                  />

                  {/* Search Button */}
                  <Button
                    onClick={fetchCreators}
                    variant="contained"
                    color="primary"
                    sx={{
                      height: "55px",
                    }}
                  >
                    {t("search")}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
          {!currentCreatorId &&
            creators &&
            (creators.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  my: 2,
                  borderRadius: "0.30rem",
                  boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                  p: 1,
                }}
              >
                <Typography sx={{ color: "black" }} variant="h4">
                  {t("selectContent")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    borderRadius: "0.30rem",
                    p: 1,
                    alignItems: "center",
                  }}
                >
                  <CloseIcon
                    sx={{ fontSize: 30, cursor: "pointer " }}
                    onClick={() => {
                      setCreators(null);
                      setSearch("");
                    }}
                  />

                  <Typography sx={{ color: "gray" }} variant="p">
                    {t("search")}: {search}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    background: "rgba(0,0,0,0.05)",
                    p: 1,
                  }}
                >
                  {creators &&
                    creators.length > 0 &&
                    creators.map((creator) => (
                      <Box
                        key={creator.customer_id}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent event from reaching parent
                          e.preventDefault(); // Prevent any default behavior
                          return assignContentCreator(
                            creator.customer_id,
                            false,
                            session
                          );
                        }}
                        sx={{ width: "250px", cursor: "pointer" }}
                      >
                        <Box sx={{ pointerEvents: "none" }}>
                          <ItemCard item={creator} />
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  my: 2,
                  borderRadius: "0.30rem",
                  boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                  p: 1,
                }}
              >
                <Typography sx={{ color: "black" }} variant="h4">
                  {t("noCreatorFound")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    borderRadius: "0.30rem",
                    p: 1,
                    alignItems: "center",
                  }}
                >
                  <CloseIcon
                    sx={{ fontSize: 30, cursor: "pointer " }}
                    onClick={() => {
                      setCreators(null);
                      setSearch("");
                    }}
                  />

                  <Typography sx={{ color: "gray" }} variant="p">
                    {t("search")}: {search}
                  </Typography>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
      {is_content_creator ? (
        <Box>
          <Typography sx={{ color: "black" }} variant="h4" mt={3}>
            {t("contentMng")}
          </Typography>
          <Typography sx={{ color: "black" }} variant="h7" mt={3}>
            ({t("minWithdraw")}: $100)
          </Typography>
          <Box
            sx={{
              borderRadius: "0.30rem",
              boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
              p: 1,
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "flex-start",
            }}
          >
            {paypal_email && (
              <>
                <Typography sx={{ color: "black" }} variant="h7">
                  {t("payEm")}: {paypal_email}
                </Typography>
                <Button
                  onClick={handleDeletePaypalEmail}
                  variant="contained"
                  color="error"
                >
                  {t("deletePay")}
                </Button>
              </>
            )}
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                cursor: "pointer",
                ":hover": {
                  transform: "scale(0.98)",
                },
              }}
              onClick={() => {
                const referralCode = session.customer.customer_id; // Replace with the actual referral code
                const rootUrl = `${window.location.origin}/app?ref=${referralCode}`;
                navigator.clipboard
                  .writeText(rootUrl)
                  .then(() => {
                    toast.success(t("reffCop")); // Optional: Provide user feedback
                  })
                  .catch((err) => {
                    console.log("err clipboard ", err);
                  });
              }}
            >
              <ContentCopyIcon />

              <Typography sx={{ color: "black" }} variant="h6">
                {t("copyReff")}
              </Typography>
            </Box>
            {is_content_creator && balance === null ? (
              <CircularProgress size={30} />
            ) : (
              <Typography sx={{ color: "black" }} variant="h4">
                {t("balance")}: ${balance}
              </Typography>
            )}

            {paypal_email ? (
              balance >= businessLogic.withdraw.minBalance ? (
                <Button
                  variant="contained"
                  color="success"
                  onClick={withdrawMoney}
                >
                  {t("withdraw")}
                </Button>
              ) : (
                <Button variant="contained" color="primary" sx={{}}>
                  ${100 - balance} {t("leftW")}
                </Button>
              )
            ) : (
              <Button
                onClick={handleAuthRedirect}
                variant="contained"
                color="primary"
              >
                {t("logPay")}
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography sx={{ color: "black" }} variant="h4" mt={3}>
            {t("becomeContent")}
          </Typography>

          <Box
            mt={3}
            sx={{
              borderRadius: "0.30rem",
              boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
              p: 1,
            }}
          >
            {followers_count >= businessLogic.contentCreator.minFollowers &&
            is_user_premium ? (
              <>
                <Typography sx={{ color: "black" }} variant="h4">
                  {t("elig")}
                </Typography>
                <Button
                  onClick={() => {
                    handleActivateContentCreator();
                  }}
                  variant="contained"
                  color="primary"
                  sx={{
                    height: "55px",
                  }}
                >
                  {t("ac")}
                </Button>
              </>
            ) : (
              <>
                <Typography sx={{ color: "black" }} variant="h4">
                  {t("req")}:
                </Typography>
                <Box
                  component={""}
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  {followers_count >=
                  businessLogic.contentCreator.minFollowers ? (
                    <CheckIcon />
                  ) : (
                    <CloseIcon />
                  )}{" "}
                  {followers_count}/{businessLogic.contentCreator.minFollowers}{" "}
                  {t("follow")}
                </Box>
                <Box
                  component={""}
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  {is_user_premium ? <CheckIcon /> : <CloseIcon />}
                  {t("prem")}
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Creator;
