import React, { useState, useCallback } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { toast } from "sonner";
import { useUserJwt, useUserSession } from "../../context/SessionProvider";
import { API_BASE_URL } from "../../utils";
import { mutate } from "swr";
import { useTranslation } from "react-i18next";

const CreateEditStatus = ({ status: prevStatus, optional }) => {
  const { t } = useTranslation(["compCreateEditStatus"]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(prevStatus);
  const jwt = useUserJwt();
  const session = useUserSession();

  const updateStatus = useCallback(
    async (newStatus) => {
      setLoading(true);
      if (newStatus && newStatus !== prevStatus) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + jwt);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ status: newStatus });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(`${API_BASE_URL}/status`, requestOptions).then(
          async (response) => {
            if (response.ok) {
              session.change.status(newStatus);
              mutate(
                {
                  url: "customerDiet",
                  customer_id: session.userSub,
                },
                undefined,
                { revalidate: true }
              );

              toast.success("Diet updated succesfully.");
            } else {
              const resJson = await response.json();
              console.log("error json status: ", resJson);
              toast.error(
                resJson.errMsg || "Diet was not updated succesfully."
              );
            }
          }
        );
      } else {
        toast.error(`The selected diet is invalid.`);
      }
      setLoading(false);
    },
    [prevStatus, jwt]
  );

  if (optional) {
    return null;
  }

  return (
    <Box
      sx={{
        borderRadius: "0.30rem",
        boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
        p: 1,
      }}
    >
      <Typography variant="h5" fontWeight="bold" marginY={1}>
        {t("diet")}
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
        <FormControl sx={{ width: "300px" }} error={!status} required>
          <InputLabel id="demo-simple-select-label">{t("diet")}</InputLabel>
          <Select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            disabled={loading}
            labelId="demo-simple-select-label"
            label={t("diet")}
          >
            <MenuItem key={t("bulk")} value={t("bulk")}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box
                  component={"img"}
                  src="/bear.png"
                  alt={t("bulkAlt")}
                  sx={{ width: "30px", height: "30px" }}
                />
                <Typography variant="p" fontWeight={"normal"}>
                  {t("bulk")}
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem key={t("def")} value={t("def")}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box
                  component={"img"}
                  src="/flamenco.png"
                  alt={t("defAlt")}
                  sx={{ width: "30px", height: "30px" }}
                />
                <Typography variant="p" fontWeight={"normal"}>
                  {t("def")}
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem
              key={t("recomposition")}
              value={t("recomposition")}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box
                  component={"img"}
                  src="/chamaleon.png"
                  alt={t("recomAlt")}
                  sx={{ width: "30px", height: "30px" }}
                />
                <Typography variant="p" fontWeight={"normal"}>
                  {t("recomposition")}
                </Typography>
              </Box>
            </MenuItem>
          </Select>
        </FormControl>
        <Button
          size="medium"
          onClick={() => updateStatus(status)}
          sx={{
            px: 2,
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
            color: "white",
            background: "black",
            ":hover": { background: "black", transform: "scale(0.95)" },
          }}
        >
          {t("save")}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateEditStatus;
