import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";
import { IMG_BASE_URL } from "../../utils";
import { useNavigate } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useTranslation } from "react-i18next";

const TopWorkoutTable = React.memo(function TopWorkoutTable({ rows, small }) {
  const { t } = useTranslation(["compTopWorkoutTable"]);
  const navigate = useNavigate();
  console.log("rows is: ", rows);
  return (
    <TableContainer component={Paper} sx={{ maxWidth: small && "550px" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">#</TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left">{t("name")}</TableCell>
            <TableCell align="left">{t("plays")}</TableCell>
            <TableCell align="left">{t("likes")}</TableCell>
            <TableCell align="left">{t("creator")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ overflow: "scroll" }}>
          {rows && rows.length > 0 ? (
            rows.map((row, photoIndex) => (
              <TableRow
                key={row.workout_id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  color: "black",
                  textDecoration: "none",
                  ":hover": {
                    background: "rgba(0,0,0,0.1)",
                  },
                  p: 0,
                  height: "50px",
                }}
                onClick={() => {
                  navigate(`/app/workout/${row.workout_id}`);
                }}
              >
                <TableCell align="left">{photoIndex + 1}</TableCell>
                <TableCell align="left">
                  <Box
                    component="img"
                    sx={{
                      height: small ? "50px" : "80px",
                      width: small ? "50px" : "80px",
                      objectFit: "cover",
                      borderRadius: "0.30rem",
                      boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                    }}
                    src={IMG_BASE_URL + row.photo_url}
                    alt="preview"
                  ></Box>
                </TableCell>
                <TableCell align="left">
                  <Box sx={{ maxWidth: "70px" }}>{row.name}</Box>
                </TableCell>
                <TableCell align="left">{row.execution_count}</TableCell>
                <TableCell align="left">{row.likes_count}</TableCell>
                <TableCell align="left">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography fontSize={13}>{row.username}</Typography>
                    {row.verified && (
                      <VerifiedIcon
                        sx={{
                          ml: 0.3,
                          width: "15px",
                          height: "15px",
                        }}
                      />
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                color: "black",
                textDecoration: "none",
                ":hover": {
                  background: "rgba(0,0,0,0.1)",
                },
                p: 0,
                height: "50px",
              }}
            >
              <TableCell colSpan={6}>
                <Typography
                  textAlign={"center"}
                  color={"rgba(0,0,0,0.4)"}
                  colum={4}
                >
                  {t("playWor")}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default TopWorkoutTable;
