import { Avatar, Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { signOut } from "aws-amplify/auth";
import React, { useState, useEffect, useRef } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useUserSession } from "../../context/SessionProvider";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useTranslation } from "react-i18next";

export default function MenuComponent() {
  const { t } = useTranslation(["compMenuComponent"]);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const session = useUserSession();
  const menuRef = useRef(null);
  const buttonRef = useRef(null); // Ref for the button that opens/closes the menu

  // Handle clicking outside the menu to close it
  useEffect(() => {
    function handleClickOutside(event) {
      // Check if the click is inside the menu or the button
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
      }
    }
    // Bind both mousedown and touchstart events
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      // Unbind the event listeners on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Button
        ref={buttonRef} // Attach ref to the button
        sx={{
          width: "100%",
          height: "100%",
          px: 3,
          display: "flex",
          justifyContent: "center",
          gap: 2,
          alignItems: "center",
          ":hover": {
            bgcolor: "rgba(0,0,0,0.05)",
            cursor: "pointer",
          },
        }}
        onClick={() => setMenuOpen((prevState) => !prevState)}
      >
        <MenuIcon fontSize="medium" />
        {menuOpen && (
          <Box
            ref={menuRef} // Attach ref to the menu box
            sx={{
              position: "absolute",
              bottom: 40,
              right: -80,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              backgroundColor: "white",
              borderRadius: "0.30rem",
              boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
              padding: "0.5rem",
              zIndex: 150,
              width: "160px",
            }}
          >
            <Box
              onClick={(e) => {
                e.stopPropagation();
                setMenuOpen(false);
                navigate("/app/premium");
              }}
              sx={{
                my: 1,
                position: "relative",
                borderRadius: "0.30rem",
                boxShadow: "0 0px 0px 0px rgba(0,0,0,1)",
                background:
                  "radial-gradient(circle, rgba(0,0,0,0.8435749299719888) 0%, rgba(242,211,0,1) 100%)",
                color: "white",
                height: "50px",
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                px: 3,
                justifyContent: "center",
                textAlign: "center",
                overflow: "hidden",

                cursor: "pointer",
                textDecoration: "none",
                ":hover": {
                  transform: "scale(0.95)",
                },
                textWrap: "nowrap",
              }}
            >
              Premium
            </Box>
            <Box
              sx={{
                width: "100%",
                maxHeight: "100%",
                height: "100%",
                py: 0.5,
                display: "flex",
                justifyContent: "center",
                gap: 2,
                alignItems: "center",
                ":hover": {
                  bgcolor: "rgba(0,0,0,0.05)",
                  cursor: "pointer",
                },
                boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setMenuOpen(false);
                navigate("/app/create");
              }}
            >
              <AddOutlinedIcon
                sx={{ textDecoration: "none", color: "black" }}
              />
              <Typography variant="p">{t("create")}</Typography>
            </Box>

            {/* Profile Section */}
            <Box
              sx={{
                width: "100%",
                maxHeight: "100%",
                height: "100%",
                py: 0.5,
                display: "flex",
                justifyContent: "center",
                gap: 2,
                alignItems: "center",
                boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",

                ":hover": {
                  bgcolor: "rgba(0,0,0,0.05)",
                  cursor: "pointer",
                },
                zIndex: 150,
              }}
              onClick={(e) => {
                e.stopPropagation();
                setMenuOpen(false);
                navigate("/app/user/" + session?.userSub);
              }}
            >
              <Avatar
                alt="Profile"
                src={session && session.picture}
                sx={{
                  maxWidth: "25px",
                  maxHeight: "25px",
                }}
              />
              <Typography variant="p">{t("profile")}</Typography>
            </Box>

            {/* Logout Section */}
            <Box
              sx={{
                width: "100%",
                maxHeight: "100%",
                height: "100%",
                py: 0.5,
                display: "flex",
                justifyContent: "center",
                px: 2,
                gap: 2,
                alignItems: "center",
                ":hover": {
                  bgcolor: "rgba(0,0,0,0.05)",
                  cursor: "pointer",
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                setMenuOpen(false);
                signOut();
              }}
            >
              <LogoutIcon fontSize="small" />
              <Typography variant="p">{t("logout")}</Typography>
            </Box>

            {/* Settings Section */}
            <Box
              sx={{
                width: "100%",
                maxHeight: "100%",
                height: "100%",
                p:0.5,
                display: "flex",
                justifyContent: "center",
                boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                gap: 1,
                alignItems: "center",
                ":hover": {
                  bgcolor: "rgba(0,0,0,0.05)",
                  cursor: "pointer",
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                setMenuOpen(false);
                navigate("/app/settings/personal");
              }}
            >
              <SettingsIcon sx={{ width: "20px", height: "20px" }} />
              <Typography variant="p">{t("settings")}</Typography>
            </Box>
          </Box>
        )}
      </Button>
    </>
  );
}
